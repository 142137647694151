import React from "react";
import { Helmet } from "react-helmet";

const GDPRPage = () => (
  <div className="gdpr-page">
    <Helmet>
      <title>Integritetspolicy</title>
    </Helmet>

    <h2>Integritetspolicy</h2>
    <p>
      Forefront Groups (Forefront) Integritetspolicy beskriver hur och varför vi
      behandlar dina personuppgifter. För oss är det viktigt att du som
      potentiell eller aktuell kund eller samarbetspartner, potentiella
      medarbetare, eller besökare på någon av våra hemsidor m.m. får dina
      personuppgifter behandlade på ett säkert och korrekt sätt. Denna policy
      omfattar samtliga svenska bolag i Forefront-koncernen. I koncernen kan ett
      eller flera bolag vara enskilt eller gemensamt personuppgiftsansvarig för
      behandlingen av dina personuppgifter. För det fall särskilt
      personuppgiftsansvar inte framgår av lag eller annan författning, eller
      rör sådana uppgifter som är lika för samtliga svenska bolag i koncernen,
      är Forefront Group AB personuppgiftsansvarig.
    </p>
  </div>
);

export default GDPRPage;
